


































































































































































import { defineComponent } from '@nuxtjs/composition-api'

// Types
import { PageContentTilesBlock } from '~/types/cms/page'
import { useCountry } from '~/composables/country/useCountry'

export default defineComponent({
  components: {},
  props: {
    tiles: {
      type: Object as () => PageContentTilesBlock,
      required: true,
    },
  },
  setup() {
    const { isCN } = useCountry()
    return { isCN }
  },
})














import { defineComponent } from '@nuxtjs/composition-api'

// Source: https://ryanmulligan.dev/blog/css-marquee/
// And: https://codepen.io/hexagoncircle/pen/eYMrGwW
export default defineComponent({
  props: {
    animationDuration: {
      type: Number,
      default: 10,
    },
    enabled: {
      type: Boolean,
      default: true,
    },
  },
})

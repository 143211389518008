export const sortMappingColors = {
  'Dark Blue': 'midnight-blue',
  Light: 'white',
  Dark: 'black',
  Blue: 'bahama-blue',
  'Light Blue': 'danube',
  'Dark Green': 'killarney',
  'Light Green': 'apple',
  Grey: 'dusty-gray',
  Red: 'monza',
  Yellow: 'hokey-pokey',
}

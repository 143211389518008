






















import { defineComponent } from '@nuxtjs/composition-api'

// Components
import HeroBanner from './variant_b_herobanner.vue'
import Tiles from './variant_b_tiles.vue'
import Video from './variant_b_video.vue'
import Popup from './variant_b_popup.vue'
import Popup2 from './variant_b_popup_2.vue'

export default defineComponent({
  name: 'HomepageB',
  components: {
    HeroBanner,
    Tiles,
    Video,
    Popup,
    Popup2,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {}
  },
})

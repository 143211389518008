








































import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'
import isEmpty from 'lodash/fp/isEmpty'
import { sortMappingColors } from '@/common/mapping/CMSMapping'
import { HeroBanner } from '~/types/cms/page'

import Marquee from '@/components/global/Marquee.vue'

export default defineComponent({
  components: { Marquee },
  props: {
    section: {
      type: Object as () => HeroBanner,
      required: true,
    },
  },
  setup(props) {
    const ctx = useContext()
    const carouselItems = [
      'Chromatin Profiling',
      'ELISA',
      'Flow Cytometry',
      'Immunocytochemistry',
      'Immunofluorescence',
      'Immunohistochemistry',
      'Immunoprecipitation',
      'Proteomics',
      'SignalStar™ Multiplex IHC',
      'Simple Western',
      'Western Blot',
    ]

    const ctaLink = computed(() => {
      return props.section.ctaButton.navigationLink.link?.href || ''
    })

    const ctaTitle = computed(() => {
      return props.section.ctaButton.navigationLink.link?.title
    })

    const ctaColorClass = computed(() => {
      const color = props.section.ctaButton.color
      const mappedColor = color && sortMappingColors[color as keyof typeof sortMappingColors]
      return mappedColor
        ? `hover:tw-bg-white hover:tw-text-${mappedColor} tw-text-white tw-bg-${mappedColor}`
        : `tw-bg-white tw-text-black`
    })

    const backgroundImage = computed(() => {
      return (
        ctx.$img(props.section.backgroundImage.cloudflareId, null, {
          provider: 'mc',
        }) + '/4X'
      )
    })

    const heroBannerColor = computed(() => {
      const textColor = props.section.style
      const mappedTextColor = textColor && sortMappingColors[textColor as keyof typeof sortMappingColors]
      return mappedTextColor ? `tw-text-${mappedTextColor}` : `tw-text-white`
    })

    const ribbonOpacity = computed(() => {
      const color = props.section.style
      const mappedRibbonColor = color && sortMappingColors[color as keyof typeof sortMappingColors]
      return mappedRibbonColor
    })

    return {
      carouselItems,
      ctaLink,
      ctaTitle,
      ctaColorClass,
      backgroundImage,
      heroBannerColor,
      ribbonOpacity,
      isEmpty
    }
  },
})

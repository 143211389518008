import { render, staticRenderFns } from "./variant_b_herobanner.vue?vue&type=template&id=2372fb34&scoped=true&"
import script from "./variant_b_herobanner.vue?vue&type=script&lang=ts&"
export * from "./variant_b_herobanner.vue?vue&type=script&lang=ts&"
import style0 from "./variant_b_herobanner.vue?vue&type=style&index=0&id=2372fb34&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2372fb34",
  null
  
)

export default component.exports